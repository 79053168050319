import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { EdetailPatientProfilesStyle } from "../styles/EdetailPatientProfilesStyle";
import { AudioTranscript } from "../components/AudioTranscript";
import {
  Container,
  ConvertedMarkdown,
  Definitions,
  Footnotes,
  References,
} from "../components";

const EdetailPatientProfiles = ({ data }) => {

  const [isHCP, setHCP] = useState(false);

  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    markdownHTML = data.allMarkdownRemark.nodes[0].html,
    audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
    isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
    arrowISI = data.allMarkdownRemark.nodes[0].frontmatter.arrowISI,
    markdownID = `ed-patient-profiles-content`;

  const [trackWProps, setTrackWProps] = useState('default');
  const [transcriptState, setTranscriptState] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [scanZoom, setScanZoom] = useState(false);

  useEffect(() => {
    if (isWindow) {
      let isHCP = sessionStorage.getItem("hcp");
      if (isHCP !== "true") {
        setHCP(false);
      } else {
        setHCP(true);
      }
    }

	// document.querySelector(`#___gatsby > div:first-of-type > div:last-of-type`).style.cssText = `z-index: 1; position: relative;`;

  }, [isHCP, setHCP]);


  return (
    <>
      <Container
	    arrowISI={arrowISI}
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        noSubNav={false}
        isiSnippet={isiSnippet}
        trackWProps={audioTracksWithProps[trackWProps]}
        setTrackWProps={setTrackWProps}
        transcriptState={transcriptState}
        setTranscriptState={setTranscriptState}
		setZoom={setZoom}
		isZoomed={zoom}
		setScanZoom={setScanZoom}
		scanZoom={scanZoom}
      >
        <EdetailPatientProfilesStyle id={`main`} className={`wrapper`} isHCP={isHCP}>
          <ConvertedMarkdown markdownID={markdownID} mdhtml={markdownHTML} />
		  <div id="patient-carousel-id" className="patient-carousel"></div>
          <Footnotes markdownID={markdownID} footnotes={dataQuery.frontmatter.footnotes} />
          <Definitions definitions={dataQuery.frontmatter.definitions} />
          <References references={dataQuery.frontmatter.references} />
          <AudioTranscript id={`audio-transcript-main`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
        </EdetailPatientProfilesStyle>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "ed-patient-profiles-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          isiSnippet
          footnotes
          references
		  arrowISI
		  audioTracksWithProps {
			  default {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  judy {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  judyVideo {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  karen {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  elisse {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  elisseVideo {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  joan {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
			  william {
				trackID
				isiContent
				src
				title
				transcript
			}
			daniela {
				trackID
				isiContent
				src
				title
				transcript
			}
	  }
        }
        id
        html
      }
    }
  }
`;

export default EdetailPatientProfiles;
