import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailPatientProfilesStyle = styled.main`
	padding-top: 2.1875rem !important;
	position: relative;

	.mobile-show {
		display: none;
		@media ${device.mobile} {
			display: block;
		}
	}

	section {
		@media ${device.mobile} {
			margin-top: 1.25rem;
		}
	}

	& > .md-container {
		position: relative;
		z-index: 1;
		@media ${device.mobile} {
			// margin-top: 90%;
		}
	}

	&.wrapper {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	p {
		@media ${device.mobile} {
			font-size: 1.333rem;
		}
	}

	p {
		@media ${device.mobile} {
			font-size: 1.333rem;
		}
	}

	#hero-container {
		/* margin-top: 5rem; */
		padding-bottom: 40%;
		position: relative;
		background-image: linear-gradient(to bottom, #ece5f4 1%, #fff 66%);

		@media ${device.mobile} {
			left: 0;
			margin-left: -4%;
			margin-top: -2.25rem;
			position: absolute;
			top: 0;
			width: 108.5%;
		}

		& > img {
			left: 0;
			margin-left: 0;
			margin-top: 0;
			max-width: 100%;
			position: absolute;
			top: 0;
			width: 97.5%;
			padding-right: 0;
			padding-top: 1rem;
			@media ${device.mobile} {
				left: 0m;
				top: 0;
				/* width: 100%; */
				width: 97%;
				margin-left: 0;
				max-width: none;
				padding-top: 3rem;
				padding-right: 1rem;
			}
		}
	}

	h2 {
		@media ${device.mobile} {
			font-size: 1.583rem;
		}
	}

	#home-cta-container {
		margin: 6.625rem auto 5rem;

		@media ${device.mobile} {
			margin: -3rem auto 5rem;
		}

		#home-ctas {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 1.25rem;

			@media ${device.mobile} {
				flex-direction: column;
				padding: 0;
				margin: 5rem auto 3.5rem auto;
			}

			& > div {
				@media ${device.mobile} {
					margin: 3.5rem auto;
				}

				&:last-of-type {
					@media ${device.mobile} {
						margin-bottom: 0;
					}
				}

				p {
					@media ${device.mobile} {
						font-size: 1.333rem;
						line-height: 1.75rem;
					}
				}

				&.checkbox::before {
					@media ${device.mobile} {
						height: 5.15rem;
					}
				}
			}
		}
	}

	a.btn-1,
	a.btn-2,
	a.btn-3 {
		@media ${device.mobile} {
			font-size: 1.166rem;
		}
	}

	.roundedges {
		bottom: -80px !important;
	}

	.hero-wrapper {
		margin-top: ${(props) => (props.isHCP ? "12.25rem" : "12rem")};
		padding: 0;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		background-color: white;
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;

		@media ${device.desktop_xlgmx} {
			margin-top: 11rem;
		}

		@media ${device.mobile} {
			position: relative;
			// margin-bottom: 22rem;
			margin-top: 11.5rem;
		}
	}

	#patient-profiles {
		padding: 0 2.5rem;

		@media ${device.mobile} {
			padding: 0;
		}

		h1 {
			margin-left: -2.5rem;
			line-height: 1.75rem;
			@media ${device.mobile} {
				margin-left: 0;
			}
			span {
				font-size: 1rem;
				color: ${(props) => props.theme.colors.brand_orange_00};
				font-weight: 600;
				@media ${device.mobile} {
					line-height: 1rem;
					display: block;
					margin-bottom: 0;
					position: relative;
					top: 1rem;
				}
			}
		}
	}
	.slick-dots {
		margin: 2.625rem 0 2.25rem 0;
		bottom: 0;
		position: inherit;
	}
	.slick-arrow {
		// bottom:-.375rem;
		bottom: 17.625rem;
		@media ${device.mobile} {
			bottom: 0rem;
		}
	}
	.slick-next > .forward {
		width: 15px !important;
		height: 30px;
		background-size: 100% 100%;
	}
	.slick-prev > .backward {
		width: 15px !important;
		height: 30px;
		background-size: 100% 100%;
	}

	.slick-prev {
		left: -21px;
		@media ${device.mobile} {
			left: 0;
		}
	}

	.slick-next {
		right: -21px;
		@media ${device.mobile} {
			right: 0;
		}
	}

	.transcript {
		margin-top: 1.875rem;
		z-index: 0;
		#pi-link {
			display: none;
		}
	}

	#audio-transcript-judy {
		margin-top: 3.75rem;
		@media ${device.mobile} {
			margin-top: 0;
		}
		.header {
			@media ${device.mobile} {
				margin-top: 3rem;
			}
		}
	}

	.modal-content-wrap {
		max-width: 71rem;
		overflow: scroll;
		position: relative;
		width: 85%;

		@media screen and (min-width: 992px) {
			height: calc(100% - 25vw) !important;
			min-height: 30vw;
		}

		@media ${device.mobile} {
			height: calc(100% - 329px) !important;
			margin-top: 1rem;
		}

		#Judy-Before-mobile-modal-popup,
		#Elisse-Before-mobile-modal-popup,
		#Karen-Before-mobile-modal-popup,
		#Joan-Before-mobile-modal-popup,
		#Daniela-Before-mobile-modal-popup,
		#William-Before-mobile-modal-popup {
			@media ${device.mobile} {
				background-color: ${(props) =>
					props.theme.colors.brand_purple_00};
				top: 0;
				width: 100%;
			}
		}

		#audio-transcript-judy #pi-link {
			display: none;
		}

		#audio-transcript-elisse #pi-link {
			display: none;
		}

		#audio-transcript-joan #pi-link {
			display: none;
		}
		#audio-transcript-william #pi-link {
			display: none;
		}
	}
`;
